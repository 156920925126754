import React, { useEffect } from 'react';
import styles from './Nav.module.scss';
import AuthService from "../../services/auth.service";

import { Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import 'flag-icons/css/flag-icons.min.css'
import { NavLngDropdown } from './NavLngDropdown';

import 'bootstrap/js/dist/collapse.js'
import 'bootstrap/js/dist/offcanvas.js'

import * as CommonIcons from '../CommonIcons';

const lngs = {
  en: { flag: 'us', nativeName: 'English' },
  ru: { flag: 'ru', nativeName: 'Русский' }
};

export { NavMenu };

function NavMenu() {
  const { t } = useTranslation();

  const onLogoutClick = async (event) => {
    event.preventDefault();
    AuthService.logout();
  };

  return (   
    <nav className={`navbar navbar-expand ${styles.navMain}`}>
      <div className="container-fluid">
        <button className={`navbar-toggler ${styles.sideMenuToggleBtn}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSideMenu" aria-controls="navbarSideMenu" aria-expanded="false" aria-label="Toggle menu">
          <span className="navbar-toggler-icon"></span>
        </button>
        <button className={`navbar-toggler ${styles.offCanvasToggleBtn}`} type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas" aria-controls="navbarOffcanvas" aria-expanded="false" aria-label="Toggle menu">
          <span className="navbar-toggler-icon"></span>
        </button>
        <a href="/" className={`navbar-brand ms-4 ${styles.navBrandTitle}`}><CommonIcons.MainLogo /> Delfihealth Telemed</a>  
        
        <Nav className={`ms-auto navbar-nav ${styles.navAccountMargin}`}> 
          <NavDropdown title={<CommonIcons.Information className={styles.informationIcon} />} align="end" id="basic-nav-dropdown" className={`dropdown-menu-end ${styles.userProfile}`}>            
            <NavDropdown.Item key="aboutProject" href="https://delfihealth.com/about" target="_blank"> {t('nav.aboutProject')}</NavDropdown.Item>
            <NavDropdown.Item key="services" href="#" disabled> {t('nav.services')}</NavDropdown.Item>
            <NavDropdown.Item key="ourExperts" href="#" disabled> {t('nav.ourExperts')}</NavDropdown.Item>
            <NavDropdown.Item key="pricing" href="#" disabled> {t('nav.pricing')}</NavDropdown.Item>
            <NavDropdown.Item key="feedback" href="https://delfihealth.com/contact" target="_blank"> {t('nav.feedback')}</NavDropdown.Item>
            <NavDropdown.Item key="about" href="/settings/about"> {t('nav.about')}</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title={<CommonIcons.Settings className={styles.accountIcon} />} align="end" id="basic-nav-dropdown" className={`dropdown-menu-end ${styles.userProfile}`}>
            <NavLngDropdown />            
            {/*<NavDropdown.Divider />*/}
          </NavDropdown>

          <NavDropdown title={<CommonIcons.Account className={styles.accountIcon} />} align="end" id="basic-nav-dropdown" className={`dropdown-menu-end ${styles.userProfile}`}>
            <NavDropdown.Item key="name" disabled className={styles.accountName}>{AuthService.getLoggedInUser().displayName}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item key="settings" href="/settings/profile"> {t('nav.settings')}</NavDropdown.Item>
            <NavDropdown.Item key="myUsers" href="/settings/my-users"> {t('nav.myUsers')}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item key="logout" href="#" onClick={onLogoutClick}>{t('nav.logOut')}</NavDropdown.Item>
          </NavDropdown>

        </Nav>
        

      </div>
    </nav>   
  )
}