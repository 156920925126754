import React, { useState, useEffect } from 'react';
import styles from './ItemImage.module.scss';
import { dateOnlyFormatter } from '../../../utils/dateFormatter';
import api from '../../../services/api';
import Loading from '../../Shared/Loading';

export default function ItemImage({ item }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [retryAfter, setRetryAfter] = useState(5); // Default retry after 5 seconds

  useEffect(() => {
    let isMounted = true;

    async function fetchImage() {
      try {
        const response = await api.get(`${item.image.url}`, { responseType: 'blob' });

        if (response.status === 202) {
          const retryAfterHeader = response.headers['retry-after'];
          const retryAfterSeconds = retryAfterHeader ? parseInt(retryAfterHeader, 10) : 5;
          setRetryAfter(retryAfterSeconds);

          if (isMounted) {
            setTimeout(fetchImage, retryAfterSeconds * 1000);
          }
        } else {
          const imageUrl = URL.createObjectURL(response.data);
          if (isMounted) {
            setImageSrc(imageUrl);
          }
        }

      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }

    fetchImage();

    return () => {
      isMounted = false;
    };

  }, [item?.image?.url]);

  if (!imageSrc) {
    return <Loading />;
  }

  return (
    <div className={styles.itemContainer}>
      {item.displayName && item.displayName !== '' &&
        <div className={styles.header}>
          <b>{item.displayName}</b>
        </div>}

      <div className={styles.imageContainer}>
        <img src={imageSrc} alt={item.image.alt} className={styles.image} />
        {/*<div className={styles.image}>{item.image.alt}</div>*/}
      </div>
      {((item.image.text && item.image.text !== "") || item.image.recordDate) && 
        <div className={styles.bottomText}>
          {item.image.text && item.image.text !== "" ? item.image.text : dateOnlyFormatter(item.image.recordDate)}
        </div>
      }
    </div>
  );
}
